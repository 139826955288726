<template>
    <div>
      <h1>About</h1>
      <p>Esta es la página de información.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutView'
  }
  </script>
  
  <style>
  /* Estilos opcionales */
  </style>
  