<template>
    <div>
      <h1>Home</h1>
      <p>Bienvenido a la página de inicio.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomeView'
  }
  </script>
  
  <style>
  /* Estilos opcionales */
  </style>
  