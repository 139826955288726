// Importar las funciones necesarias de vue-router
import { createRouter, createWebHistory } from 'vue-router'

// Importar los componentes de vista que se utilizarán en las rutas
import HomeView from '../views/Home.vue'
import AboutView from '../views/About.vue'

// Definir las rutas de la aplicación
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  }
]

// Crear el enrutador utilizando createRouter y createWebHistory
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // Esto permite el historial de navegación
  routes // Asigna las rutas definidas al enrutador
})

// Exportar el enrutador para ser usado en main.js
export default router
