// Importaciones de Vue y la aplicación principal
import { createApp } from 'vue'
import App from './App.vue'

// Importaciones de Bootstrap y jQuery para estilos y funcionalidades
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

// Importar el router configurado
import router from './router'

// Importar el store de Vuex configurado
import store from './store'

// Crear y montar la aplicación Vue, usando el router y el store
createApp(App)
  .use(router) // Usar el router para la navegación
  .use(store)  // Usar el store de Vuex para la gestión del estado
  .mount('#app') // Montar la aplicación en el elemento con id 'app'
