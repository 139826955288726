import { createStore } from 'vuex'

// Crear el store de Vuex
const store = createStore({
  state() {
    return {
      // Definir el estado inicial
      count: 0
    }
  },
  mutations: {
    // Definir las mutaciones para modificar el estado
    increment(state) {
      state.count++
    }
  },
  actions: {
    // Definir las acciones para ejecutar mutaciones
    increment(context) {
      context.commit('increment')
    }
  }
})

// Exportar el store para usarlo en la aplicación Vue
export default store
